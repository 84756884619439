import React, { ReactNode } from 'react';

import StyledHeader from '@components/TextHeader/styles';

export enum TAG {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
}

export enum SIZE {
  XS = 14,
  SM = 18,
  MD = 24,
  LG = 48,
  XL = 56,
}

export enum WEIGHT {
  NORMAL = 500,
  MEDIUM = 600,
  BOLD = 700,
}

type Props = {
  tag?: React.ElementType;
  size: SIZE;
  weight: WEIGHT;
  children: ReactNode | string;
};

function TextHeader({ tag, size, weight, children }: Props) {
  const Header = tag;
  return (
    <StyledHeader as={Header} textSize={size} weight={weight}>
      {children}
    </StyledHeader>
  );
}

TextHeader.defaultProps = {
  tag: 'h1',
};

export default TextHeader;
