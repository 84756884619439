import React, { useCallback, useRef, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'next-i18next';

import { useAppContext } from '@context/AppContext';
import ToggableList from '@components/ToggableList';
import { Avatar, Trigger, Wrapper, Chevron, MenuWrapper, MenuItem } from '@components/Navbar/UserMenu/styles';

import AvatarPlaceholder from '@assets/images/icons/avatar-placeholder.svg';
import ChevronIcon from '@assets/images/icons/chevron-blue.svg';
import routes from '@utils/routes';
import { STUDENT_TABS, EMPLOYER_TABS } from '@constants/profileTabs';

function UserMenu() {
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const ref = useRef();
  const { t } = useTranslation('navbar');

  const { profile, signOut, userRole } = useAppContext();

  const toggleMenuVisibility = useCallback(() => {
    setMenuIsVisible(!menuIsVisible);
  }, [menuIsVisible]);

  let profileUrl: string;
  switch (userRole) {
    case 'student':
      profileUrl = `${routes.profile}?tab=${STUDENT_TABS.SKILLS}`;
      break;
    case 'employer':
      profileUrl = `${routes.profile}?tab=${EMPLOYER_TABS.COMPANY_DETAILS}`;
      break;
    default:
      profileUrl = routes.homepage;
      break;
  }

  return (
    <Wrapper type="button" ref={ref} onClick={toggleMenuVisibility}>
      <Trigger>
        <Avatar>
          <Image src={profile.avatar || AvatarPlaceholder} width={40} height={40} alt="" objectFit="cover" />
        </Avatar>
        <Chevron isOpened={menuIsVisible}>
          <Image src={ChevronIcon} width={24} height={24} alt="" />
        </Chevron>
      </Trigger>
      <CSSTransition in={menuIsVisible} timeout={300} classNames="slide-in" unmountOnExit>
        <ToggableList element={ref} setVisibility={setMenuIsVisible}>
          <MenuWrapper>
            <MenuItem>
              <Link href={profileUrl}>
                <a href={profileUrl}>{t('My account')}</a>
              </Link>
            </MenuItem>
            <MenuItem>
              <button type="button" onClick={signOut}>
                {t('Sign out')}
              </button>
            </MenuItem>
          </MenuWrapper>
        </ToggableList>
      </CSSTransition>
    </Wrapper>
  );
}

export default UserMenu;
