import styled from '@emotion/styled';
import { fontSize } from '@styles/globalStyles';
import { boolean } from 'yup';

type ContainerProps = {
  centered: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: ${({ centered }) => (centered ? 'center' : 'flex-start')};
  flex-wrap: wrap;
`;

export const Label = styled.label<{ withError?: boolean }>`
  font-size: ${fontSize(14)};
  line-height: 1.71;
  letter-spacing: -0.21px;
  color: ${({ withError, theme }) => (withError ? theme.colors.borders.invalid : '#00202d')};
  text-align: left;
  cursor: pointer;
  flex: 1 0 calc(100% - 43px);
`;

export const StyledControl = styled.input`
  &[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    color: ${({ theme }) => theme.colors.button.primary.default};
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    border: 1.5px solid ${({ theme }) => theme.colors.button.primary.default};
    border-radius: 0.15rem;
    transform: translateY(-0.075em);
    display: inline-grid;
    place-content: center;
    margin-right: 23px;
    position: relative;
    top: 3px;

    &.invalid {
      color: ${({ theme }) => theme.colors.borders.invalid};
      border-color: ${({ theme }) => theme.colors.borders.invalid};
    }

    &:before {
      content: '';
      width: 16px;
      height: 16px;
      clip-path: polygon(34% 42%, 46% 55%, 72% 30%, 80% 38%, 46% 69%, 25% 51%);
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: ${({ theme }) => `inset 1em 1em ${theme.colors.button.primary.default}`};
      background-color: ${({ theme }) => theme.colors.button.primary.default};
    }

    &:checked:before {
      transform: scale(1);
    }
  }

  &[type='radio'] {
    background-color: #fff;
    margin: 0 10px 0 0;
    color: ${({ theme }) => theme.colors.button.primary.default};
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-grid;
    place-content: center;
    cursor: pointer;

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: ${({ theme }) => `inset 1em 1em ${theme.colors.button.primary.default}`};
      background-color: ${({ theme }) => `${theme.colors.button.primary.default}`};
    }

    &:checked:before {
      transform: scale(1);
    }
  }
`;

export const ErrorMessage = styled.p`
  font-size: ${fontSize(12)};
  line-height: 1.67;
  color: ${({ theme }) => theme.colors.borders.invalid};
  margin-left: 43px;
`;
