import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.div`
  position: relative;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const Trigger = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled.div`
  border: 1px #ccecf9 solid;
  box-shadow: 0 4px 10px 0 rgba(0, 64, 91, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media only screen and ${breakpoint.xl} {
    width: 30px;
    height: 30px;
  }
`;

export const Chevron = styled.div<{ isOpened: boolean }>`
  transition: all 0.3s ease-in-out;
  margin-left: 24px;
  margin-top: ${({ isOpened }) => (isOpened ? 0 : 3)}px;
  transform: ${({ isOpened }) => (isOpened ? 'rotate(180deg)' : 'none')};

  @media only screen and ${breakpoint.xl} {
    margin-left: 6px;
  }
`;

export const MenuWrapper = styled.ul`
  display: block;
  background-color: ${({ theme }) => theme.colors.navbar.dropdown.background};
  position: absolute;
  top: calc(100% + 15px);
  box-shadow: 0 4px 4px 0 rgba(0, 35, 48, 0.1);
  right: 0;
  border-radius: 3px;
  width: 200px;
  padding: 25px 0;
`;

export const MenuItem = styled.li`
  > a,
  > button {
    padding: 12px 24px;
    color: ${({ theme }) => theme.colors.navbar.dropdown.text};
    display: block;
    text-decoration: none;
    background-color: transparent;
    border: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
  }
`;
