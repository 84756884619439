import styled from '@emotion/styled';
import SearchImage from '@assets/images/icons-search.svg';

const LinkContainer = styled.div`
  a{
    background: url(${SearchImage.src}) no-repeat 8px 6px;
    padding: 8px;
    padding-bottom: 8px;
    padding-left: 48px;
    display: inline-block;
    font-family: ${({ theme }) => theme.defaults.fontFamily.secondary};
    letter-spacing: -0.24px;
    line-height: 1.5;
    color: #00202d;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-align: left;
    color: #00202d;
    text-decoration: none;
    cursor: pointer;

    &:hover{
      color: #e6007e;
    }
  }
}`;

export default LinkContainer;
