import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';
import { ContentWrapper, fontSize } from '@styles/globalStyles';

export const Newsletter = styled(ContentWrapper)`
  width: 100%;
  height: 582px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: unset;

  h4 {
    font-size: ${fontSize(36)};
    max-width: 860px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 72px;
  }

  @media only screen and ${breakpoint.xl} {
    height: 470px;

    h4 {
      font-size: ${fontSize(32)};
      margin-bottom: 56px;
    }
  }

  @media only screen and ${breakpoint.lg} {
    height: 400px;

    h4 {
      font-size: ${fontSize(28)};
      margin-bottom: 42px;
    }
  }

  @media only screen and ${breakpoint.md} {
    height: 320px;

    h4 {
      font-size: ${fontSize(24)};
      margin-bottom: 28px;
    }
  }
`;

export const ErrorMessage = styled.p`
  display: block;
  font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: -0.18px;
  color: #e6007e;
  margin-top: 2px;
  margin-bottom: 20px;
`;

export const FormNewsletter = styled.form`
  padding-top: 70px;

  button {
    width: 416px;
    margin-top: 20px;
  }

  input[type='text'] {
    max-width: 410px;
  }

  .custom-control {
    margin-bottom: 30px;
  }

  @media only screen and ${breakpoint.md} {
    padding-top: 20px;
    button {
      width: 100%;
    }
  }

  @media only screen and ${breakpoint.sm} {
    input[type='checkbox'] {
      margin-right: 10px;
    }
  }
`;

export const ConfirmationText = styled.p`
  text-align: center;
  margin-top: 90px;
  @media only screen and ${breakpoint.md} {
    margin-top: 100px;
  }
`;

export const Switcher = styled.div`
  display: flex;

  .custom-control {
    + .custom-control {
      margin-left: 40px;
    }
  }
`;
