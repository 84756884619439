import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { fontSize } from '@styles/globalStyles';

export enum BUTTON_THEME {
  PRIMARY,
  SECONDARY,
  TEXT,
  ALERT,
}

export enum BUTTON_SIZE {
  SMALL,
  MEDIUM,
}

type ButtonProps = {
  buttonTheme: BUTTON_THEME;
  href?: string;
};

export const PreloaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(2 103 146 / 50%);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15px;

  img {
    width: 40px;
    height: 40px;
  }
`;

export const StyledButton = styled.button<ButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  font-size: ${fontSize(16)};
  letter-spacing: -0.21px;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
  position: relative;
  overflow: hidden;

  ${({ size, theme }) => {
    let fs: number;

    switch (size) {
      case BUTTON_SIZE.SMALL:
        fs = theme.sizes.button.fontSize.small;
        break;
      case BUTTON_SIZE.MEDIUM:
        fs = theme.sizes.button.fontSize.medium;
        break;
      default:
        fs = theme.sizes.button.fontSize.medium;
        break;
    }

    return css`
      font-size: ${fontSize(fs)};
    `;
  }}

  ${({ buttonTheme, theme }) => {
    let backgroundColor: string;
    let borderColor: string;
    let textColor: string;
    let activeColor: string;
    let disabledColor: string;
    let hoverColor: string;

    switch (buttonTheme) {
      case BUTTON_THEME.PRIMARY:
        backgroundColor = theme.colors.button.primary.default;
        borderColor = theme.colors.button.primary.default;
        textColor = theme.colors.button.primary.text;
        hoverColor = theme.colors.button.primary.onHover;
        activeColor = theme.colors.button.primary.click;
        disabledColor = theme.colors.button.primary.disabled;
        break;

      case BUTTON_THEME.SECONDARY:
        backgroundColor = theme.colors.button.secondary.background;
        borderColor = theme.colors.button.secondary.default;
        textColor = theme.colors.button.secondary.default;
        hoverColor = theme.colors.button.secondary.onHover;
        activeColor = theme.colors.button.secondary.click;
        disabledColor = theme.colors.button.secondary.disabled;
        break;

      case BUTTON_THEME.TEXT:
        backgroundColor = theme.colors.button.textButton.background;
        borderColor = theme.colors.button.textButton.default;
        textColor = theme.colors.button.textButton.default;
        hoverColor = theme.colors.button.textButton.onHover;
        activeColor = theme.colors.button.textButton.click;
        disabledColor = theme.colors.button.textButton.disabled;
        break;

      case BUTTON_THEME.ALERT:
        backgroundColor = theme.colors.button.alert.background;
        borderColor = theme.colors.button.alert.default;
        textColor = theme.colors.button.alert.text;
        hoverColor = theme.colors.button.alert.onHover;
        activeColor = theme.colors.button.alert.click;
        disabledColor = theme.colors.button.alert.disabled;
        break;

      default:
        backgroundColor = theme.colors.button.primary.default;
        borderColor = theme.colors.button.primary.default;
        textColor = theme.colors.button.primary.text;
        hoverColor = theme.colors.button.primary.onHover;
        activeColor = theme.colors.button.primary.click;
        disabledColor = theme.colors.button.primary.disabled;
        break;
    }

    return css`
      background-color: ${backgroundColor};
      border: ${buttonTheme === BUTTON_THEME.TEXT ? 'none' : `1px solid ${borderColor}`};
      color: ${textColor};
      min-width: ${buttonTheme === BUTTON_THEME.TEXT ? 0 : 130}px;
      min-height: ${buttonTheme === BUTTON_THEME.TEXT ? 0 : 40}px;
      padding: ${buttonTheme === BUTTON_THEME.TEXT ? 0 : '8px 17px'};

      &:hover {
        color: ${buttonTheme === BUTTON_THEME.PRIMARY || BUTTON_THEME.ALERT ? textColor : hoverColor};
        background-color: ${buttonTheme === BUTTON_THEME.PRIMARY || BUTTON_THEME.ALERT ? hoverColor : backgroundColor};
        border: ${buttonTheme === BUTTON_THEME.TEXT ? 'none' : `1px solid ${hoverColor}`};
      }

      &:active {
        color: ${buttonTheme === BUTTON_THEME.PRIMARY || BUTTON_THEME.ALERT ? textColor : activeColor};
        background-color: ${buttonTheme === BUTTON_THEME.PRIMARY || BUTTON_THEME.ALERT ? activeColor : backgroundColor};
        border: ${buttonTheme === BUTTON_THEME.TEXT ? 'none' : `1px solid ${activeColor}`};
      }

      &:disabled {
        color: ${buttonTheme === BUTTON_THEME.TEXT ? disabledColor : textColor};
        background-color: ${buttonTheme === BUTTON_THEME.TEXT ? backgroundColor : disabledColor};
        border: none;
        cursor: default;
      }
    `;
  }};
`;
