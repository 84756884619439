import styled from '@emotion/styled';
import { fontSize } from '@styles/globalStyles';

const StyledLink = styled.a`
  background-color: inherit;
  font-size: ${fontSize(14)};
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.14;
  letter-spacing: -0.21px;
  font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
  color: ${({ theme }) => theme.colors.link.primary};

  &:hover {
    color: ${({ theme }) => theme.colors.link.onHover};
  }

  &:active {
    color: ${({ theme }) => theme.colors.link.click};
  }
`;

export default StyledLink;
