import React, { ReactNode, MouseEvent } from 'react';
import Link from 'next/link';
import { StyledButton, BUTTON_THEME, BUTTON_SIZE, PreloaderWrapper } from '@components/Button/styles';
import Preloader, { POSITIONS } from '@components/Preloader';

import { useAppContext } from '@context/AppContext';
import { KEYS, getItem } from '@utils/cache';

export { BUTTON_THEME };
export { BUTTON_SIZE };

export enum BUTTON_TYPE {
  SUBMIT = 'submit',
  RESET = 'reset',
  BUTTON = 'button',
}

type Props = {
  children: ReactNode | string;
  buttonTheme?: BUTTON_THEME;
  href?: string;
  disabled?: boolean;
  onClick?: (event?: MouseEvent) => void;
  type?: BUTTON_TYPE;
  size?: BUTTON_SIZE;
  isLoading?: boolean;
  shallow?: boolean;
  replace?: boolean;
  className?: string;
};

function Button({
  children,
  href,
  disabled,
  buttonTheme,
  onClick,
  type,
  size,
  isLoading,
  shallow,
  replace,
  className,
}: Props) {
  const { useHighContrastTheme } = useAppContext();

  const Btn = (
    <StyledButton
      as={href ? 'a' : 'button'}
      {...(href ? { href } : {})}
      buttonTheme={buttonTheme}
      size={size}
      highContrast={useHighContrastTheme}
      disabled={disabled || isLoading}
      onClick={onClick}
      type={type}
      className={className}
    >
      {isLoading ? (
        <PreloaderWrapper>
          <Preloader position={POSITIONS.RELATIVE} />
        </PreloaderWrapper>
      ) : null}
      {children}
    </StyledButton>
  );

  if (href) {
    return (
      <Link href={href} shallow={shallow} replace={replace} passHref>
        {Btn}
      </Link>
    );
  }

  return Btn;
}

Button.defaultProps = {
  className: null,
  href: '',
  disabled: false,
  buttonTheme: BUTTON_THEME.PRIMARY,
  onClick: null,
  type: BUTTON_TYPE.BUTTON,
  size: BUTTON_SIZE.MEDIUM,
  isLoading: false,
  shallow: false,
  replace: false,
};

export default Button;
