import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { fontSize } from '@styles/globalStyles';

export const Container = styled.div<{ isOpen: boolean }>`
  background-color: #f6f7f8;
  width: 0;
  height: calc(100vh - 48px);
  position: fixed;
  top: 48px;
  right: 0;
  overflow: hidden;
  transition: 0.3s;
  z-index: 99999;

  ${({ isOpen }) =>
    isOpen
      ? css`
          width: 100%;
          transition: 0.3s;
        `
      : null}

  img[alt='arrow-menu'] {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 0;
    top: 7px;
    transition: 0.3s all;

    &.arrow-on {
      transform: rotate(180deg);
    }
  }

  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 45px 24px;
  display: flex;
  flex-direction: column;

  a[href="https://mlodziwlodzi.pl/"]
  {
    display: block;
    width: 40px;
    height: 40px;
    margin: 32px 0;
    margin-left: 24px;
  }

  ul {
    display: block;
    height: unset;

    li {
      display: block;
      padding: 8px 0;
      margin: 24px 0;

      a {
        font-size: ${fontSize(16)};
      }
    }
  }

  button:last-of-type {
    display: block;
    font-size: ${fontSize(16)};
  }

  a[type='button']:last-of-type {
    display: block;
    min-height: 40px;
    text-align: center;
    font-size: ${fontSize(14)};
    padding: 12px 17px 8px 17px;
    margin-top: 20px;
    @supports (-webkit-touch-callout: none) {
      margin-bottom: 80px;
    }
  }
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;

  img {
    position: absolute;
    top: 7px;
    left: 15px;
    width: 18px;
    height: 18px;
  }
`;

export const MobileSearch = styled.input`
  width: 100%;
  background-color: inherit;
  border: none;
  border-bottom: 1px solid #009fe3;
  padding: 8px 20px 8px 45px;
  font-size: ${fontSize(16)};

  &:focus {
    outline: none;
  }
`;

export const MobileContentMenu = styled.div`
  border-bottom: 1px solid #009fe3;
  padding-bottom: 40px;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 85%;

  .menu-item {
    height: unset;

    &:first-child {
      margin-top: 0;
    }
  }

  .language-selector {
    padding-left: 13px;
    margin-bottom: 24px;
  }

  .menu {
    flex: 1;
    overflow-y: scroll;
  }
`;

export const DropdownMenu = styled.ul`
  max-height: 0 !important;
  overflow: hidden;
  transition: max-height 0.3s ease-in;

  &.dropdown-on {
    max-height: 500px !important;
  }
`;

export const DropmenuItem = styled.li`
  &:last-of-type {
    margin-bottom: 0;
  }
`;
