import { useEffect, useState } from 'react';
import { breakpoint, BREAKPOINTS } from '@styles/breakpoints';

let debounce: ReturnType<typeof setTimeout>;

function useBreakpoint(delay = 300) {
  const [width, setWidth] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      clearTimeout(debounce);
      debounce = setTimeout(() => {
        setWidth(window.innerWidth);
      }, delay);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [delay]);

  const matchBreakpoint = (bp: BREAKPOINTS): boolean => {
    if (typeof window !== 'undefined') {
      return window.matchMedia(breakpoint[bp]).matches;
    }
    return null;
  };

  return { matchBreakpoint, width, BREAKPOINTS };
}

export default useBreakpoint;
