import React, { useRef, useEffect, useState, useCallback, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { Backdrop, Modal, ModalClose, ModalInner } from '@components/Modal/styles';

interface ClientOnlyPortalProps {
  children: ReactNode | ReactNode[];
  selector?: string;
  isOpen: boolean;
  closeHandler: () => void;
}

function ClientOnlyPortal({ children, selector, isOpen, closeHandler }: ClientOnlyPortalProps) {
  const ref = useRef();
  const [mounted, setMounted] = useState(false);

  const handleCloseOnEsc = useCallback(
    (event) => {
      if (event.which === 27) {
        closeHandler();
      }
    },
    [closeHandler]
  );

  useEffect(() => {
    ref.current = document.querySelector(selector);
    setMounted(true);
  }, [selector]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.removeAttribute('style');
    }
  }, [isOpen]);

  // add observer for ESC key
  useEffect(() => {
    window.addEventListener('keydown', handleCloseOnEsc);
    return () => {
      window.removeEventListener('keydown', handleCloseOnEsc);
      document.body.removeAttribute('style');
    };
  }, [handleCloseOnEsc]);

  const clickingOnModalHandler = useCallback((event) => {
    event.stopPropagation();
  }, []);

  if (!mounted) {
    return null;
  }

  const ModalWrapper = (
    <CSSTransition in={isOpen} timeout={300} classNames="alert" unmountOnExit>
      <Backdrop onClick={closeHandler}>
        <Modal onClick={clickingOnModalHandler} className="modal">
          <ModalInner>{children}</ModalInner>
          <ModalClose onClick={closeHandler} aria-label="Close" />
        </Modal>
      </Backdrop>
    </CSSTransition>
  );

  return createPortal(ModalWrapper, ref.current);
}

ClientOnlyPortal.defaultProps = {
  selector: '#modal',
};

export default ClientOnlyPortal;
