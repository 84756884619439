import { useEffect, RefObject, ReactElement } from 'react';

type Props = {
  setVisibility: (isVisible: boolean) => void;
  element: RefObject<HTMLDivElement>;
  children: ReactElement;
};

function ToggableList({ setVisibility, element, children }: Props) {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (element.current && !element.current.contains(event.target)) {
        setVisibility(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [element, setVisibility]);

  return children;
}

export default ToggableList;
