import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';
import { ContentWrapper, fontSize } from '@styles/globalStyles';

export const PageHeader = styled.header`
  width: 100%;
  height: 72px;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom: 1px #f6f7f8 solid;

  @media only screen and ${breakpoint.xl} {
    a[type='button']:last-of-type {
      min-width: 120px;
      min-height: 35px;
      font-size: ${fontSize(13)};
    }
  }

  @media only screen and ${breakpoint.lg} {
    a[type='button']:last-of-type {
      min-width: 110px;
      min-height: 30px;
      font-size: ${fontSize(12)};
    }
  }

  @media only screen and ${breakpoint.md} {
    height: 48px;

    a[type='button']:last-of-type {
      display: none;
    }
  }
`;

export const Content = styled(ContentWrapper)`
  display: flex;
  align-items: center;
  height: 100%;

  @media only screen and (max-width: 1350px) {
    padding: 0 15px;
  }

  @media only screen and ${breakpoint.md} {
    .navbar-outside-link {
      display: none;
    }
  }
`;
export const LogoContainer = styled.a`
  width: 50px;
  height: 50px;

  @media only screen and ${breakpoint.lg} {
    width: 40px;
    height: 40px;
  }

  @media only screen and ${breakpoint.md} {
    width: 30px;
    height: 30px;
  }
`;

export const Menu = styled.ul`
  display: flex;
  height: 100%;
  margin-left: 36px;

  & span {
    box-shadow: ${({ theme }) => theme.shadow.medium};
  }

  @media only screen and ${breakpoint.xl} {
    margin-left: 24px;
  }

  @media only screen and ${breakpoint.lg} {
    margin-left: 12px;
  }
`;

export const MenuItem = styled.li<{ active?: boolean }>`
  position: relative;
  font-weight: 500;
  letter-spacing: -0.2304px;
  height: 100%;

  > a {
    color: ${({ theme, active }) => (active ? theme.colors.navigationLink.hover : theme.colors.navigationLink.default)};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    width: 100%;
    transition: all 0.3s ease-in-out;
    padding: 0 28px;
    height: 100%;
    font-size: ${fontSize(16)};
    font-weight: 600;
    white-space: nowrap;

    &:after {
      content: attr(title);
      content: attr(title) / '';
      height: 0;
      visibility: hidden;
      overflow: hidden;
      user-select: none;
      pointer-events: none;
      font-weight: 600;

      @media speech {
        display: none;
      }
    }
  }

  &.menu-item-link:hover {
    > a {
      color: ${({ theme }) => theme.colors.navigationLink.hover};
      font-weight: 600;
    }
    > ul {
      max-height: 400px;
      padding: 25px 0;
      transform: scaleY(1);
      transition: all 0.5s cubic-bezier(0.42, 0, 0.17, 1.34) 0.1s;
    }
  }

  @media only screen and ${breakpoint.xl} {
    a {
      padding: 0 18px;
      font-size: ${fontSize(14)};
    }
  }

  @media only screen and ${breakpoint.lg} {
    a {
      padding: 0 12px;
    }
  }

  @media only screen and ${breakpoint.md} {
    display: none;

    ul li a {
      width: 200px;
      color: #e6007e;
      text-decoration: none;
    }
  }
`;

export const Buttons = styled.div`
  height: 40px;
  width: 150px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-column-gap: 15px;

  & button {
    border-radius: 3px;
    cursor: pointer;
    background-color: #fff;
    border: none;
    width: 40px;
    height: 40px;
  }

  .search-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #009fe3;

    div {
      width: 100%;
      height: 100%;

      span {
        margin-top: 5px !important;
      }
    }
  }

  @media only screen and ${breakpoint.xl} {
    width: 110px;

    button {
      width: 30px;
      height: 25px;
    }
  }

  @media only screen and ${breakpoint.md} {
    width: 70px;
    margin-right: 15px;

    .search-button {
      display: none;
    }

    span:last-of-type {
      display: none;
    }
  }
`;

export const Grow = styled.div`
  flex: 1;
`;

export const DropdownMenu = styled.ul`
  position: absolute;
  top: calc(100% + 1px);
  left: 28px;
  color: #ffc9f5;
  border-radius: 3px;
  box-shadow: 0 4px 4px 0 rgba(0, 35, 48, 0.1);
  background-color: ${({ theme }) => theme.colors.navbar.dropdown.background};
  width: max-content;
  transform: scaleY(0);
  overflow: hidden;
  transform-origin: top center;
  transition: all 0.5s ease-in-out 0s;
`;

export const DropmenuItem = styled.li`
  a {
    color: ${({ theme }) => theme.colors.navbar.dropdown.text};
    font-size: ${fontSize(14)};
    font-weight: 500;
    letter-spacing: -0.21px;
    text-decoration: none;
    padding: 12px 24px;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease-in-out;

    &:after {
      content: attr(title);
      content: attr(title) / '';
      height: 0;
      visibility: hidden;
      overflow: hidden;
      user-select: none;
      pointer-events: none;
      font-weight: 600;

      @media speech {
        display: none;
      }
    }
  }

  &:hover a {
    color: #fff;
    font-weight: 600;
  }

  @media only screen and ${breakpoint.md} {
    display: none;
  }
`;

export const DropmenuMargin = styled.div`
  background-color: #fff;
  width: 100%;
  position: absolute;
  height: 27px;

  @media only screen and ${breakpoint.md} {
    display: none;
  }
`;

export const IconLink = styled.a`
  display: flex;
  align-items: center;
  margin-left: 28px;
  box-shadow: 0 4px 10px 0 rgba(0, 64, 91, 0.1);

  @media only screen and ${breakpoint.xl} {
    margin-left: 20px;
    width: 40px;
    height: 40px;
  }

  @media only screen and ${breakpoint.lg} {
    margin-left: 12px;
    width: 30px;
    height: 30px;
  }

  // @media only screen and ${breakpoint.md} {
  //   display: none;
  // }
`;

export const MobileButtonsContainer = styled.div`
  min-width: 35px;
  text-align: right;
`;

export const SearchBarContainer = styled(ContentWrapper)`
  position: relative;
`;

export const SearchBarContent = styled.div`
  width: 448px;
  min-height: 72px;
  padding: 16px;
  border-radius: 3px;
  box-shadow: 4px 8px 20px 0 rgba(0, 64, 91, 0.08);
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 160px;
`;
