import React from 'react';
import { toast } from 'react-toastify';
import Router from 'next/router';
import { UseTranslation, withTranslation } from 'next-i18next';
import { GetServerSideProps } from 'next';
import { getCookie } from 'cookies-next';

import { KEYS } from '@utils/cache';
import { withAppContext, AppContextType } from '@context/AppContext';
import { API, APIRoutes } from '@utils/api';
import routes from '@utils/routes';

type Options = {
  redirectRoute?: string;
  shouldBeAuthorized: boolean;
};

interface AuthenticatedRouteProps {
  context: AppContextType;
  renderComponent: boolean;
  t: UseTranslation;
}

function authenticatedRoute(Component = null, options: Options = { shouldBeAuthorized: true }) {
  // eslint-disable-next-line react/prefer-stateless-function
  class AuthenticatedRoute extends React.Component<AuthenticatedRouteProps> {
    async componentDidMount() {
      const {
        context: { isAuthenticated },
        t,
      } = this.props;

      const shouldRedirect = options.shouldBeAuthorized ? !isAuthenticated : isAuthenticated;

      if (shouldRedirect) {
        await Router.replace(options.redirectRoute);
        if (options.shouldBeAuthorized) {
          toast(t('This content is visible only for authenticated users.'));
        }
      }
    }

    async componentDidUpdate(prevProps) {
      const {
        context: { isAuthenticated },
        t,
      } = this.props;

      if (prevProps.context.isAuthenticated !== isAuthenticated) {
        const shouldRedirect = options.shouldBeAuthorized ? !isAuthenticated : isAuthenticated;

        if (shouldRedirect) {
          await Router.replace(options.redirectRoute);
          if (options.shouldBeAuthorized) {
            toast(t('This content is visible only for authenticated users.'));
          }
        }
      }
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  return withTranslation()(withAppContext(AuthenticatedRoute));
}

export function requireAuthentication(gssp: GetServerSideProps) {
  return async (context) => {
    const { req, res } = context;
    const token = getCookie(KEYS.AUTH_TOKEN, { req, res });

    if (token) {
      try {
        await API.get(APIRoutes.me);
      } catch ({ response }) {
        return {
          redirect: {
            destination: routes.signin,
            statusCode: 301,
          },
        };
      }
    } else {
      // Redirect to login page
      return {
        redirect: {
          destination: routes.signin,
          statusCode: 301,
        },
      };
    }

    return await gssp(context); // eslint-disable-line
  };
}

export default authenticatedRoute;
