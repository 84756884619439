import styled from '@emotion/styled';
// eslint-disable-next-line import/no-cycle
import { POSITIONS } from '@components/Preloader';
import { css } from '@emotion/react';

type PreloaderWrapperProps = {
  position: POSITIONS;
  overlay: boolean;
};

export const Wrapper = styled.div<PreloaderWrapperProps>`
  position: ${({ position }) => position};
  ${({ overlay }) =>
    overlay
      ? css`
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 1001;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        `
      : null}
`;

export const Icon = styled.div`
  position: sticky;
  top: 100px;
  z-index: 1001;
  text-align: center;
`;

export const Overlay = styled.div`
  background-color: #f6f7f8e3;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
`;
