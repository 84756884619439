const prefix = 'apprentice';

export enum KEYS {
  AUTH_TOKEN = 'authToken',
  FONT_SCALE = 'fontScale',
  HIGH_CONTRAST = 'highContrast',
  USER_PROFILE = 'userProfile',
  USER_ROLE = 'userRole',
}

const prepareKey = (key: KEYS) => `${prefix}::${key}`;

export const setItem = (key: KEYS, value: string): void => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(prepareKey(key), value);
  }
};

export const getItem = (key: KEYS): string | null => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(prepareKey(key));
  }

  return null;
};

export const removeItem = (key: KEYS): void => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(prepareKey(key));
  }
};

export const clearCache = (): void => {
  if (typeof window !== 'undefined') {
    Object.keys(KEYS).forEach((key: KEYS) => removeItem(key));
  }
};
