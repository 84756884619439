import styled from '@emotion/styled';
import SearchImage from '@assets/images/icons-search.svg';
import CloseImage from '@assets/images/icons-close.svg';
// eslint-disable-next-line import/no-extraneous-dependencies
import { motion } from 'framer-motion';

export const SearchBarContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 448px
  height: 42px;
  background-color: #fff;
`;

export const SearchInputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;

export const SearchInput = styled.input`
  width: 416px;
  height: 40px;
  padding: 8px;
  font-size: 16px;
  color: #00405b;
  border-radius: 3px;
  border: solid 1px #ccecf9;
  background-color: transparent;
  padding-left: 48px;

  &:focus {
    outline: none;
    &::placeholder {
      opacity: 0;
    }
  }

  &::placeholder {
    color: #bebebe;
    transition: all 250ms ease-in-out;
  }
`;
export const SearchIcon = styled.div`
  width: 24px;
  height: 24px;
  background: url(${SearchImage.src}) no-repeat center center / contain;
  position: absolute;
  top: 10px;
  left: 8px;
  cursor: pointer;
  z-index: 3;
  background-color: white;
`;

export const CloseIcon = styled(motion.span)`
  cursor: pointer;
  background: url(${CloseImage.src}) no-repeat center center / contain;
  width: 22px;
  height: 22px;
  position: absolute;
  top: 10px;
  right: 8px;
`;

export const SearchContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  overflow-y: auto;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WarningMessage = styled.span`
  color: #a1a1a1;
  font-size: 14px;
  display: flex;
  align-self: center;
  justify-self: center;
`;
