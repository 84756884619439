import React, { ReactNode } from 'react';
import NextLink from 'next/link';

import StyledLink from '@components/Link/styles';

type Props = {
  children: ReactNode | string;
  href: string;
  replace?: boolean;
};

function Link({ children, href, replace }: Props) {
  return (
    <NextLink href={href} passHref {...(replace ? { replace: true } : {})}>
      <StyledLink>{children}</StyledLink>
    </NextLink>
  );
}

Link.defaultProps = {
  replace: false,
};

export default Link;
