import React, { useMemo } from 'react';

import { Container, Label, StyledInput, ErrorMessage } from '@components/Form/Input/styles';
import { FieldError } from 'react-hook-form';

export enum INPUT_TYPES {
  TEXT = 'text',
  EMAIL = 'email',
  NUMBER = 'number',
  PASSWORD = 'password',
}

type Props = {
  label: string;
  onChange: (value: string) => void;
  onFocus?: any;
  onBlur?: any;
  value: string;
  step?: string;
  inputType: INPUT_TYPES;
  name: string;
  error?: FieldError;
  autoComplete?: string;
  textArea?: boolean;
  placeholder?: string;
  complete?: boolean;
  min?: string;
};

function Input({
  label,
  inputType,
  onChange,
  value,
  name,
  error,
  autoComplete,
  textArea,
  placeholder,
  onFocus,
  onBlur,
  step,
  complete,
  min,
}: Props) {
  const inputId = useMemo(() => `input_${name}-${inputType}`, [name, inputType]);
  const isTextArea = textArea ? 'textarea' : 'input';
  return (
    <Container>
      <Label htmlFor={inputId}>{label}</Label>
      <StyledInput
        {...(autoComplete ? { autoComplete } : {})}
        {...(min ? { min } : {})}
        id={inputId}
        type={inputType}
        step={inputType === INPUT_TYPES.NUMBER ? step : null}
        as={isTextArea}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        className={error && 'invalid'}
        autoComplete={complete ? 'off' : 'on'}
      />
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </Container>
  );
}

Input.defaultProps = {
  error: undefined,
  autoComplete: null,
  textArea: false,
  placeholder: '',
  onBlur: null,
  onFocus: null,
  complete: false,
  min: null,
  step: 1,
};

export default Input;
