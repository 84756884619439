import React from 'react';
import Image from 'next/image';
import PreloaderIcon from '@assets/images/preloader.svg';
// eslint-disable-next-line import/no-cycle
import { Wrapper, Overlay, Icon } from '@components/Preloader/styles';

export enum POSITIONS {
  ABSOLUTE = 'absolute',
  RELATIVE = 'relative',
}

export enum SIZES {
  SMALL = 38,
  MEDIUM = 50,
  LARGE = 100,
}

type PreloaderProps = {
  position?: POSITIONS;
  size?: SIZES;
  overlay?: boolean;
};

function Preloader({ position, size, overlay }: PreloaderProps) {
  return (
    <Wrapper position={position} overlay={overlay}>
      <Icon>
        <Image width={size} height={size} src={PreloaderIcon} />
      </Icon>
      {overlay ? <Overlay /> : null}
    </Wrapper>
  );
}

Preloader.defaultProps = {
  position: POSITIONS.ABSOLUTE,
  size: SIZES.SMALL,
  overlay: false,
};

export default Preloader;
