import React from 'react';
import Link from 'next/link';
import LinkContainer from '@components/Autocomplete/SearchItem/styles';

type ItemProps = {
  name: string;
  href: string;
};

function SearchItem({ name, href }: ItemProps) {
  return (
    <LinkContainer>
      <Link href={href}>
        <a href={href}>{name}</a>
      </Link>
    </LinkContainer>
  );
}

export default SearchItem;
