import React, { useCallback } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';

import authenticatedRoute from '@hocs/authenticatedRoute';
import { Container, LeftColumn, RightColumn, CloseButtonContainer } from '@components/AuthLayout/styles';
import closeBtn from '@assets/images/ic-close.svg';
import routes from '@utils/routes';

function AuthLayout({ children, referer }) {
  const router = useRouter();

  const closeClickHandler = useCallback(() => {
    if (!referer) {
      router.push('/');
    } else {
      router.back();
    }
  }, [router, referer]);

  return (
    <Container>
      <LeftColumn />
      <RightColumn className="auth-layout">
        <CloseButtonContainer onClick={closeClickHandler}>
          <Image src={closeBtn} />
        </CloseButtonContainer>
        {children}
      </RightColumn>
    </Container>
  );
}

export default authenticatedRoute(AuthLayout, {
  redirectRoute: routes.homepage,
  shouldBeAuthorized: false,
});
