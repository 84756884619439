/* eslint-disable import/no-cycle */
import React from 'react';
import { css, Global, useTheme } from '@emotion/react';
import emotionReset from 'emotion-reset';
import styled from '@emotion/styled';
import defaultTheme from '@styles/theme';
import ReactPaginate from 'react-paginate';
import { breakpoint } from '@styles/breakpoints';

import ChevronsRightIcon from '@assets/images/icons/chevrons-right.svg';
import ChevronsLeftIcon from '@assets/images/icons/chevrons-left.svg';

export enum FONT_SCALE {
  NORMAL = 'normal',
  MEDIUM = 'medium',
  LARGE = 'large',
}

function GlobalStyles() {
  const theme = useTheme();

  return (
    <Global
      styles={css`
        ${emotionReset}

        *,
        *:after,
        *:before {
          box-sizing: border-box;
          -webkit-font-smoothing: subpixel-antialiased;
          -moz-osx-font-smoothing: grayscale;
          padding: 0;
          margin: 0;
        }

        :root {
          --toastify-toast-width: auto;
        }

        html {
          font-size: ${theme.defaults.fontSize}px;

          &.fontsize--${FONT_SCALE.NORMAL} {
            font-size: ${theme.defaults.fontSize}px;
          }
          &.fontsize--${FONT_SCALE.MEDIUM} {
            font-size: ${theme.defaults.mediumFontSize}px;
          }
          &.fontsize--${FONT_SCALE.LARGE} {
            font-size: ${theme.defaults.largeFontSize}px;
          }

          &.highcontrast {
            section,
            main,
            nav,
            header,
            footer > div,
            #offers,
            #employers,
            .auth-layout,
            .mobile-menu,
            .partners,
            .modal,
            .search-bar,
            .single-offer,
            .contact-page {
              background-color: ${theme.colors.highContrast.primary};
              background-image: none;

              p,
              a,
              h1,
              h2,
              h3,
              h4,
              h5,
              h6,
              span,
              div,
              label,
              li,
              input::placeholder {
                color: ${theme.colors.highContrast.secondary};

                &:hover {
                  color: ${theme.colors.highContrast.secondary};
                }
              }

              div label {
                z-index: 9;
              }

              input,
              #employers a {
                background-color: ${theme.colors.highContrast.primary};
                border: 2px solid ${theme.colors.highContrast.secondary};
              }

              a[type='button'],
              button {
                background-color: ${theme.colors.highContrast.secondary};
                color: ${theme.colors.highContrast.primary} !important;
                border-color: ${theme.colors.highContrast.secondary};
              }

              .simple-dropdown__control,
              div[type='standalone'],
              .simple-dropdown__menu-list,
              .react-select__menu-list,
              .react-select__control,
              .menu-item-link ul,
              .slide-in-enter-done,
              .slide-in-exit-active,
              .slide-in-enter,
              .offer-details,
              .profile,
              .application,
              .react-select,
              textarea {
                border: 2px solid ${theme.colors.highContrast.secondary};
                background-color: ${theme.colors.highContrast.primary};
                color: ${theme.colors.highContrast.secondary};

                .simple-dropdown__option:hover,
                .react-select__option:hover {
                  background-color: ${theme.colors.highContrast.primary};
                }
              }

              .highcontrast-select {
                div {
                  border-color: ${theme.colors.highContrast.secondary};
                  color: ${theme.colors.highContrast.secondary};
                }
              }

              .react-select__menu {
                z-index: 999;
              }

              .offer-box {
                background-color: ${theme.colors.highContrast.primary};
                box-shadow: none;
                border: 2px solid ${theme.colors.highContrast.secondary};
              }

              input {
                background-color: ${theme.colors.highContrast.primary};
                color: ${theme.colors.highContrast.secondary};
              }
            }

            section:before {
              background: none;
            }

            section,
            main,
            footer {
              border-top: 2px solid ${theme.colors.highContrast.secondary};
            }

            header,
            .mobile-menu {
              border-bottom: none;

              span {
                color: ${theme.colors.highContrast.primary};
              }
            }

            .single-offer {
              * {
                color: ${theme.colors.highContrast.secondary} !important;
              }
            }

            .search-bar {
              div {
                background-color: ${theme.colors.highContrast.primary};
              }
            }

            .rule-schedule {
              background-color: ${theme.colors.highContrast.secondary};
            }

            .schedule-box {
              border-color: ${theme.colors.highContrast.secondary};

              div {
                background-color: ${theme.colors.highContrast.primary};
                border: 2px solid ${theme.colors.highContrast.secondary};
              }
            }
          }
        }

        body {
          font-family: ${theme.defaults.fontFamily.primary};

          &.navbar-is-open {
            overflow-y: hidden;
          }
        }

        strong {
          font-weight: 700;
        }

        #__next {
          display: flex;
          flex-direction: column;
          min-height: 100vh;

          main {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            z-index: 1;
          }
        }

        #modal {
          z-index: 9999;
          position: relative;
        }

        .Toastify__toast-container {
          max-width: 620px;
        }

        .slide-in-enter {
          opacity: 0;
          transform: scaleY(0);
          transform-origin: top center;
        }
        .slide-in-enter-active {
          opacity: 1;
          transform: scaleY(1);
          transition: opacity 300ms, transform 300ms;
          transform-origin: top center;
        }
        .slide-in-exit {
          opacity: 1;
          transform: scaleY(1);
          transform-origin: top center;
        }
        .slide-in-exit-active {
          opacity: 0;
          transform: scaleY(0);
          transition: opacity 300ms, transform 300ms;
        }

        .Toastify {
          position: relative;
          z-index: 99991;
        }
      `}
    />
  );
}

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.layoutMaxWidth}px;
  margin: 0 auto;

  @media screen and (max-width: 1350px) {
    padding: 0 15px;
  }
`;

export const ErrorPage = styled.div`
  text-align: center;
`;

export const GlobalError = styled.div`
  color: ${({ theme }) => theme.colors.error};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -50px;
  width: 100%;
`;

export const GlobalErrorIcon = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 10px;
`;

export const fontSize = (size: number): string => {
  const mathSize = size / defaultTheme.defaults.fontSize;
  return `${mathSize.toFixed(3)}rem`;
};

export const Pagination = styled(ReactPaginate)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  flex-wrap: wrap;
  grid-row-gap: 12px;

  a {
    display: block;
    min-width: 30px;
    cursor: pointer;
    padding: 10px;
    margin: 0 5px;
    background: transparent;
    /* background-color: ${({ theme }) => theme.colors.button.primary.default}; */
    color: ${({ theme }) => theme.colors.button.primary.default};
    border-radius: 3px;
    transition: border-color 0.3s ease-in-out;
    font-size: 18px;
    border: 1px solid transparent;

    &:hover {
      border-color: ${({ theme }) => theme.colors.button.primary.default};
    }
  }

  .selected a {
    background-color: ${({ theme }) => theme.colors.button.primary.default};
    color: ${({ theme }) => theme.colors.button.primary.text};
  }

  .previous a,
  .next a {
    text-indent: -9999px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 26px;
  }

  .previous a {
    background-image: url(${ChevronsLeftIcon.src});
  }

  .next a {
    background-image: url(${ChevronsRightIcon.src});
  }

  @media only screen and ${breakpoint.sm} {
    a {
      margin: 0 3px;
    }
  }
`;

export const Card = styled.div`
  padding: 32px;
  border-radius: 3px;
  box-shadow: 0 4px 4px 4px rgba(214, 214, 214, 0.2);
  background-color: #fff;
`;

export const ListDotted = styled.ul<{ dotColor?: string }>`
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: flex;
    font-size: ${fontSize(16)};
    align-items: flex-start;
    padding: 8px 0;

    &:before {
      content: '';
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background-color: ${({ dotColor }) => dotColor || '#00202d'};
      margin: 10px 10px 0 0;
    }
  }
`;
export const WorkTypeLabel = styled.h2`
  margin-top: 10px;
  font-size: ${fontSize(10)};
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ type, theme }) => theme.colors.work_types[type]};
`;

export const OfferTypeLabel = styled.h2`
  font-size: ${fontSize(14)};
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ type, theme }) => theme.colors.offers[type]};
`;

export const ErrorMessage = styled.p`
  font-size: ${fontSize(12)};
  line-height: 1.67;
  color: ${({ theme }) => theme.colors.borders.invalid};
`;

export default GlobalStyles;
