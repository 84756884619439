import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const Backdrop = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  backdrop-filter: blur(16px);
  background-color: rgba(0, 32, 45, 0.9);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  &.alert-enter {
    opacity: 0;
  }
  &.alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  &.alert-exit {
    opacity: 1;
  }
  &.alert-exit-active {
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
  }
`;

export const Modal = styled.div`
  position: relative;
  width: 90%;
  max-width: 860px;
  min-height: 320px;
  max-height: 90vh;
  padding: 32px;
  background-color: #fff;
  overflow: auto;

  @media only screen and ${breakpoint.lg} {
    width: calc(100% - 50px);
  }
`;

export const ModalInner = styled.div`
  width: 640px;
  margin: auto;
  padding-bottom: 40px;

  @media only screen and ${breakpoint.md} {
    width: unset;
    margin-top: 45px;
  }
`;

export const ModalClose = styled.button`
  border: 0;
  cursor: pointer;
  position: absolute;
  top: 32px;
  right: 32px;
  background: url('/images/icon-close.svg') no-repeat center center;
  width: 32px;
  height: 32px;
`;
