export enum STUDENT_TABS {
  SKILLS = 'umiejetnosci',
  APPLICATIONS = 'aplikacje',
}

export enum EMPLOYER_TABS {
  COMPANY_DETAILS = 'dane-firmy',
  OFFERS = 'ofety',
  OFFER = 'oferta',
  APPLICATIONS = 'aplikacje',
}
