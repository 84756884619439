import React, { useMemo, ReactElement } from 'react';
import { Container, Label, StyledControl, ErrorMessage } from '@components/Form/Control/styles';

export enum CONTROL_TYPE {
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
}

type Props = {
  type?: CONTROL_TYPE;
  onChange: (...event: []) => void;
  label: string | ReactElement;
  name: string;
  value: string;
  error?: {
    message: string;
  };
  htmlLabel?: boolean;
  checked?: boolean;
  centered?: boolean;
};

function Control({ centered, type, label, name, error, onChange, value, htmlLabel, checked }: Props) {
  const inputId = useMemo(() => `control_${name}-${type}-${value}`, [name, type, value]);

  return (
    <Container className="custom-control" centered={centered}>
      <StyledControl
        name={name}
        id={inputId}
        type={type}
        value={value}
        onChange={onChange}
        className={error && 'invalid'}
        checked={checked}
      />

      {label !== '' && !htmlLabel && (
        <Label htmlFor={inputId} withError={!!error}>
          {label}
        </Label>
      )}
      {label !== '' && htmlLabel && (
        <Label htmlFor={inputId} withError={!!error} dangerouslySetInnerHTML={{ __html: label.toString() }} />
      )}
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </Container>
  );
}

Control.defaultProps = {
  type: CONTROL_TYPE.RADIO,
  error: undefined,
  htmlLabel: false,
  checked: false,
  centered: false,
};

export default Control;
