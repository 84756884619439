import styled from '@emotion/styled';
import { fontSize } from '@styles/globalStyles';

const Header = styled.h1<{ textSize: number }>`
  font-family: ${({ theme }) => theme.defaults.fontFamily.secondary};
  font-size: ${(props) => fontSize(props.textSize)};
  font-weight: ${(props) => props.weight};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.84px;
  color: #002330;
  margin: 0;
`;

export default Header;
