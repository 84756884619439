import styled from '@emotion/styled';
import { fontSize } from '@styles/globalStyles';

export const Container = styled.div<{ showStrength?: boolean }>`
  width: 100%;
  position: relative;
  margin-bottom: ${({ showStrength }) => (showStrength ? 14 : 40)}px;
  text-align: left;
`;

export const Label = styled.label`
  font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
  font-size: ${fontSize(12)};
  line-height: 1.67;
  color: #00202d;
`;

export const StyledInput = styled.input`
  height: 40px;
  margin: 4px 0 0;
  border-radius: 3px;
  border: ${({ theme }) => `solid 1px ${theme.colors.borders.primary}`};
  background-color: #fff;
  padding: 0 10px;
  display: block;
  width: 100%;

  &.invalid {
    border-color: ${({ theme }) => theme.colors.borders.invalid};
  }

  &:focus {
    outline: none;
  }
`;

export const ErrorMessage = styled.p<{ showStrength?: boolean }>`
  font-size: ${fontSize(12)};
  line-height: 1.67;
  color: ${({ theme }) => theme.colors.borders.invalid};
  position: absolute;
  ${({ showStrength }) => (showStrength ? 'bottom: 0;' : null)}
`;

export const VisibilityButton = styled.button`
  width: 20px;
  height: 14px;
  border: none;
  background: none;
  cursor: pointer;
  display: block;
  position: absolute;
  z-index: 2;
  right: 18px;
  top: 33px;
`;

export const VisibilityButtonIcon = styled.div<{ visible: boolean }>`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;
