export const size = {
  xs: 320,
  sm: 450,
  md: 769,
  lg: 1024,
  xl: 1200,
  xxl: 1600,
};

export const breakpoint = {
  xs: `(max-width: ${size.xs}px)`,
  sm: `(max-width: ${size.sm}px)`,
  md: `(max-width: ${size.md}px)`,
  lg: `(max-width: ${size.lg}px)`,
  xl: `(max-width: ${size.xl}px)`,
  xxl: `(max-width: ${size.xxl}px)`,
};

export enum BREAKPOINTS {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = 'xxl',
}
