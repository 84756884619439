/* eslint-disable import/no-cycle */
import React, { createContext, useContext, ReactNode, useMemo, useState, useCallback, useEffect } from 'react';
import { setCookies } from 'cookies-next';
import Router from 'next/router';
import { FONT_SCALE } from '@styles/globalStyles';
import { getItem, setItem, KEYS } from '@utils/cache';
import { API, APIRoutes, destroySession } from '@utils/api';
import routes from '@utils/routes';

export type AppContextType = {
  isAuthenticated: boolean;
  fontScale: FONT_SCALE;
  switchFontScale: () => void | null;
  switchTheme: () => void | null;
  useHighContrastTheme: boolean;
  profile: User;
  setUser: (user: User) => void;
  unsetUser: () => void;
  signOut: () => void;
  authenticateFromCache: () => void;
  userRole: 'employer' | 'student';
};

const AppContextDefaultState: AppContextType = {
  isAuthenticated: false,
  fontScale: FONT_SCALE.NORMAL,
  switchFontScale: null,
  switchTheme: null,
  useHighContrastTheme: false,
  profile: null,
  setUser: null,
  unsetUser: null,
  signOut: null,
  authenticateFromCache: null,
  userRole: null,
};

export const AppContext = createContext<AppContextType>(AppContextDefaultState);

export function useAppContext() {
  return useContext(AppContext);
}

type Props = {
  children: ReactNode;
  userProfile: User;
  isAuthenticated: boolean;
};

export function AppContextProvider({ children, userProfile, isAuthenticated: authenticated }: Props) {
  const [fontScale, setFontScale] = useState<FONT_SCALE>(
    FONT_SCALE[getItem(KEYS.FONT_SCALE)?.toUpperCase()] || FONT_SCALE.NORMAL
  );
  const [useHighContrastTheme, setHighContrast] = useState(JSON.parse(getItem(KEYS.HIGH_CONTRAST)));
  const [profile, setProfile] = useState(userProfile);
  const [userRole, setUserRole] = useState(userProfile?.accountType);
  const [isAuthenticated, setIsAuthenticated] = useState(authenticated);

  if (userRole) {
    setCookies(KEYS.USER_ROLE, userRole);
  }

  useEffect(() => {
    setFontScale(FONT_SCALE[getItem(KEYS.FONT_SCALE)?.toUpperCase()] || FONT_SCALE.NORMAL);
    setHighContrast(JSON.parse(getItem(KEYS.HIGH_CONTRAST)));
  }, []);

  const switchFontScale = useCallback(() => {
    const currentScale: string = getItem(KEYS.FONT_SCALE) || FONT_SCALE.NORMAL;
    let newFontScale: FONT_SCALE;
    switch (currentScale) {
      case FONT_SCALE.NORMAL:
        newFontScale = FONT_SCALE.MEDIUM;
        break;
      case FONT_SCALE.MEDIUM:
        newFontScale = FONT_SCALE.LARGE;
        break;
      default:
        newFontScale = FONT_SCALE.NORMAL;
        break;
    }

    setFontScale(newFontScale);
    setItem(KEYS.FONT_SCALE, newFontScale);
  }, []);

  // switch theme between normal & highcontrast
  const switchTheme = useCallback(() => {
    const isHighContrast = !useHighContrastTheme;
    setHighContrast(isHighContrast);
    setItem(KEYS.HIGH_CONTRAST, isHighContrast.toString());
  }, [useHighContrastTheme]);

  // handle font scaling & high contrast mode
  useEffect(() => {
    Object.values(FONT_SCALE).forEach((scale) => document.documentElement.classList.remove(`fontsize--${scale}`));
    document.documentElement.classList.add(`fontsize--${fontScale}`);

    if (useHighContrastTheme) {
      document.documentElement.classList.add('highcontrast');
    } else {
      document.documentElement.classList.remove('highcontrast');
    }
  }, [fontScale, useHighContrastTheme]);

  const setUser = (user: User) => {
    setProfile(user);
    setUserRole(user.accountType);
    setCookies(KEYS.USER_ROLE, user.accountType);
    setIsAuthenticated(true);
  };

  const unsetUser = () => {
    setIsAuthenticated(false);
    setProfile(null);
    destroySession();
  };

  const signOut = async () => {
    try {
      await API.delete(APIRoutes.auth.signout);
      await Router.replace(routes.homepage);
      setIsAuthenticated(false);
      setProfile(null);
      destroySession();
    } catch (error) {
      console.debug(error); // eslint-disable-line no-console
    }
  };

  const authenticateFromCache = useCallback(() => {
    if (getItem(KEYS.USER_PROFILE)) {
      setIsAuthenticated(true);
      setProfile(JSON.parse(getItem(KEYS.USER_PROFILE)));
    }
  }, []);

  const value = useMemo(
    () => ({
      ...AppContextDefaultState,
      fontScale,
      switchFontScale,
      switchTheme,
      profile,
      isAuthenticated,
      setUser,
      unsetUser,
      signOut,
      authenticateFromCache,
      useHighContrastTheme,
      userRole,
    }),
    [
      fontScale,
      switchFontScale,
      switchTheme,
      profile,
      isAuthenticated,
      authenticateFromCache,
      userRole,
      useHighContrastTheme,
    ]
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export function withAppContext(Component) {
  return function WrapperComponent(props) {
    return <AppContext.Consumer>{(state) => <Component {...props} context={state} />}</AppContext.Consumer>;
  };
}
