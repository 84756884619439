import React, { useState } from 'react';
import { useTranslation, Trans } from 'next-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import { API, APIRoutes } from '@utils/api';

import ClientOnlyPortal from '@components/Modal/ClientOnlyPortal';
import Button, { BUTTON_TYPE } from '@components/Button';
import Control, { CONTROL_TYPE } from '@components/Form/Control';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {
  Newsletter as NewsletterContainer,
  ErrorMessage,
  FormNewsletter,
  ConfirmationText,
  Switcher,
} from '@components/Newsletter/styles';

import TextInput, { INPUT_TYPES } from '@components/Form/Input';
import TextHeader, { TAG, SIZE, WEIGHT } from '@components/TextHeader';
import Link from '@components/Link';

import Lottie from 'react-lottie';
import successAnimation from '@assets/lottie/mail-sent.json';

function Newsletter() {
  const { t } = useTranslation('newsletter');
  const [openPortal, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const [openConfirmation, setConfirmation] = useState(false);
  const newsletterSchema = Yup.object().shape({
    accountType: Yup.string().default('student').required('Required'),
    // name: Yup.string().required(t('input-error')),
    email: Yup.string().email(t('input-error-2')).required(t('input-error')),
    rodo: Yup.boolean().required(t('rodo required')).oneOf([true], t('rodo required')),
    termsAndConditions: Yup.boolean().required(t('terms required')).oneOf([true], t('terms required')),
  });

  const formOptions = { resolver: yupResolver(newsletterSchema) };
  const { handleSubmit, formState, control, reset } = useForm(formOptions);
  const { errors } = formState;

  const { mutate: subscribeToNewsletter } = useMutation((data) =>
    API.post(APIRoutes.newsletter, { newsletterSubscriber: data })
  );

  const onSubmitHandler = (userData) => {
    subscribeToNewsletter(userData, {
      onError: ({ response: { data } }) => {
        setError(data);
      },
      onSuccess: () => {
        setConfirmation(true);
        setError(null);
        reset();
      },
    });
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <NewsletterContainer as="section">
      <TextHeader size={SIZE.MD} tag={TAG.H4} weight={WEIGHT.NORMAL}>
        {t('header')}
      </TextHeader>

      <Button onClick={() => setOpen(true)}>{t('button')}</Button>
      <ClientOnlyPortal closeHandler={() => setOpen(false)} isOpen={openPortal}>
        {!openConfirmation && (
          <FormNewsletter onSubmit={handleSubmit(onSubmitHandler)}>
            <Switcher>
              <Controller
                control={control}
                name="accountType"
                defaultValue="student"
                render={({ field: { name, onChange, value } }) => (
                  <>
                    <Control
                      name={name}
                      onChange={onChange}
                      value="student"
                      type={CONTROL_TYPE.RADIO}
                      label={t('Candidate')}
                      checked={value === 'student'}
                      centered
                    />
                    <Control
                      name={name}
                      onChange={onChange}
                      value="employer"
                      type={CONTROL_TYPE.RADIO}
                      label={t('Employer')}
                      checked={value === 'employer'}
                      centered
                    />
                  </>
                )}
              />
            </Switcher>
            {/* <Controller
              name="name"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <TextInput
                  name={name}
                  inputType={INPUT_TYPES.TEXT}
                  onChange={onChange}
                  value={value}
                  label={t('label-name')}
                  error={errors.name}
                />
              )}
            /> */}

            <Controller
              name="email"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <TextInput
                  name={name}
                  inputType={INPUT_TYPES.TEXT}
                  onChange={onChange}
                  value={value}
                  label={t('label-email')}
                  error={errors[name]}
                />
              )}
            />

            <Controller
              control={control}
              name="rodo"
              render={({ field: { name, onChange, value } }) => (
                <Control
                  onChange={onChange}
                  value="true"
                  name={name}
                  error={errors[name]}
                  label={t('input-checkbox-1')}
                  type={CONTROL_TYPE.CHECKBOX}
                  checked={value}
                />
              )}
            />
            <Controller
              control={control}
              name="termsAndConditions"
              render={({ field: { name, onChange, value } }) => (
                <Control
                  onChange={onChange}
                  value="true"
                  name={name}
                  error={errors[name]}
                  checked={value}
                  label={
                    <Trans i18nKey="newsletter">
                      Wyrażam zgodę na przesłanie Newslettera zgodnie z{' '}
                      <Link href="/documents/regulamin-uslugi-newsletter">Regulaminem</Link> świadczenia usługi
                      Newsletter.
                    </Trans>
                  }
                  type={CONTROL_TYPE.CHECKBOX}
                />
              )}
            />

            {/* <ErrorMessage className="additional">
              {errors.rodo?.message || errors.terms_and_conditions?.message ? t('checkbox-error') : ''}
            </ErrorMessage> */}
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <Button type={BUTTON_TYPE.SUBMIT}>{t('button-save')}</Button>
          </FormNewsletter>
        )}

        {openConfirmation && (
          <>
            <ConfirmationText>{t('confirmation')}</ConfirmationText>
            <Lottie options={defaultOptions} height={480} />
          </>
        )}
      </ClientOnlyPortal>
    </NewsletterContainer>
  );
}
export default Newsletter;
