import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import { useClickOutside } from 'react-click-outside-hook';
import MoonLoader from 'react-spinners/MoonLoader';
import { useQuery, useQueryClient } from 'react-query';
import { API, APIRoutes } from '@utils/api';
import {
  SearchBarContainer,
  SearchInputContainer,
  SearchIcon,
  SearchInput,
  CloseIcon,
  SearchContent,
  LoadingWrapper,
  WarningMessage,
} from '@components/Autocomplete/SearchBar/styles';
import useDebounce from '@hooks/debounceHook';
import SearchItem from '@components/Autocomplete/SearchItem';
import { prepareLink } from '@utils/dataHelpers';

const containerVariants = {
  expanded: {
    height: '250px',
  },
  collapsed: {
    height: '42px',
  },
};
const containerTransition = { type: 'spring', damping: 22, stiffness: 150 };

const fetchQuickSearch = async (query: string) => {
  const { data } = await API.get(APIRoutes.quickSearch(query));

  const mergedResults = [];
  data.offers.forEach((offer) => {
    mergedResults.push({ type: 'offer', ...offer });
  });
  data.employers.forEach((employer) => {
    mergedResults.push({ type: 'employer', ...employer });
  });

  return mergedResults;
};

function SearchBar() {
  const { t } = useTranslation('autocomplete');
  const inputRef = useRef();
  const [parentRef, isClickedOutside] = useClickOutside();
  const [isExpanded, setExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [noSearchResults, setNoSearchResults] = useState(false);
  const queryClient = useQueryClient();

  const {
    data: searchResults,
    isFetching,
    isFetched,
  } = useQuery(['quickSearch', searchQuery], () => fetchQuickSearch(searchQuery), {
    enabled: false,
    initialData: [],
    keepPreviousData: true,
  });

  const isEmpty = !searchResults || searchResults.length === 0;

  const changeHandler = (e) => {
    e.preventDefault();
    setSearchQuery(e.target.value);
  };

  const expandContainer = () => {
    setExpanded(true);
  };

  const collapseContainer = () => {
    setExpanded(false);
    setSearchQuery('');
  };

  const searchOffer = async () => {
    if (!searchQuery || searchQuery.trim().length < 3) return;

    queryClient.prefetchQuery(['quickSearch', searchQuery], () => fetchQuickSearch(searchQuery));
  };

  useEffect(() => {
    if (isFetched) {
      setNoSearchResults(!searchResults.length);
    }
  }, [searchResults, isFetched]);

  useEffect(() => {
    if (isClickedOutside) collapseContainer();
  }, [isClickedOutside]);

  useEffect(() => {
    if (searchQuery.length >= 3) expandContainer();
  }, [searchQuery]);

  useDebounce(searchQuery, 300, searchOffer);

  return (
    <SearchBarContainer
      animate={isExpanded ? 'expanded' : 'collapsed'}
      variants={containerVariants}
      transition={containerTransition}
      ref={parentRef}
    >
      <SearchInputContainer>
        <SearchIcon />
        <SearchInput
          autoFocus
          placeholder={t('Search')}
          // onFocus={expandContainer}
          ref={inputRef}
          value={searchQuery}
          onChange={changeHandler}
        />
        {isExpanded && (
          <CloseIcon
            key="close-icon"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={collapseContainer}
            transition={{ duration: 0.2 }}
          />
        )}
      </SearchInputContainer>
      {isExpanded && (
        <SearchContent>
          {isFetching && (
            <LoadingWrapper>
              <MoonLoader loading color="#000" size={20} />
            </LoadingWrapper>
          )}
          {!isFetching && isEmpty && !noSearchResults && (
            <LoadingWrapper>
              <WarningMessage>{t('Search')}</WarningMessage>
            </LoadingWrapper>
          )}
          {!isFetching && noSearchResults && (
            <LoadingWrapper>
              <WarningMessage>{t('No results found')}</WarningMessage>
            </LoadingWrapper>
          )}
          {!isFetching &&
            !isEmpty &&
            searchResults.map((item) => (
              <SearchItem
                key={`${item.type}_${item.id}`}
                name={item.name}
                href={prepareLink(item.type, item.slug, item.id)}
              />
            ))}
        </SearchContent>
      )}
    </SearchBarContainer>
  );
}

export default SearchBar;
